import React from "react"
import { StaticQuery, graphql } from "gatsby"

import ContentPage from "../views/page"
const context = {
  locale: 'de'
}
const page = {
  Titel: '404 - Seite nicht gefunden',
  TitelEn: '404 - Page not found',
  navigation: {
    path: '404'
  },
  Inhalte: [
    {
      strapi_component: 'website-content.rich-text',
      Titel: '404 - Seite nicht gefunden',
      TitelEn: '404 - Page not found',
      Text: 'Leider konnte unter der von Ihnen angegebenen Adresse kein Inhalt gefunden werden.   \n[Zurück zur Startseite](/)',
      TextEn: 'Unfortunately there is no content on this adress.   \n[Back to the homepage](/)',
    }
  ]
}
const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
    query NotFoundQuery {
      allStrapiWebsiteTextbaustein {
        nodes {
          Text
          TextEn
          Titel
        }
      }
      allStrapiNavigation {
        nodes {
          items {
            id
            slug
            path
            related {
              Titel
              TitelEn
              hideInMenu
              showInFooter
            }
          }
          path
          strapiId
          related {
            Titel
            TitelEn
            hideInMenu
            showInFooter
          }
          slug
        }
      }
    }
  `}
    render={data => (
      <ContentPage data={{allStrapiNavigation: data.allStrapiNavigation, strapiPage: page, allStrapiWebsiteTextbaustein: data.allStrapiWebsiteTextbaustein}} pageContext={context}></ContentPage>
    )}
  />
)

export default NotFoundPage
